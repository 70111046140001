import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { baseQuery } from '../middleware';
import { IChangeUserPassword, IChangeUserStatus, IHerriotType, IUserAllInfo } from '../../models/base/user';
import { IParams, IServerResponse } from '../../models/models';
import { getTokenFromLocalStorage } from '../../utils/func';
import usersService from './users.service';

interface IRolesChangeParam {
    user_id: number | string;
    role_id: number;
    auth_id: number | string;
}
interface ILocationChangeParam {
    user_id: number | string;
    region_id?: number;
    district_id?: number;
}

export interface IRegistration {
    [x: string]: string;
}
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers, { endpoint }) => {
        const token = getTokenFromLocalStorage();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        // Отключаем 'Content-Type: application/json' для запросов с `FormData`
        const formDataEndpoints = ['setUserAvatar']; // Добавь сюда еще запросы, если нужно

        if (!formDataEndpoints.includes(endpoint)) {
            headers.set('Content-Type', 'application/json');
        }

        headers.set('Accept', 'application/json');
        return headers;
    },
});


export const usersApi = createApi({
    reducerPath: 'users/api',
    tagTypes: ['Users'],
    baseQuery,
    endpoints: (build) => ({
        getUsers: build.query<IServerResponse<IUserAllInfo[]>, IParams>({
            query: (params) => ({
                url: `users/list`,
                method: 'GET',
                params,
            }),
            transformResponse(response: IServerResponse<IUserAllInfo[]>) {
                return usersService.prepareUsersList(response);
            },
            providesTags: (result) =>
                result?.data
                    ? [
                          ...result.data.map(({ user_id }) => ({ type: 'Users' as const, user_id })),
                          { type: 'Users', id: 'LIST' },
                      ]
                    : [{ type: 'Users', id: 'LIST' }],
        }),
        getUser: build.query<IServerResponse<IUserAllInfo>, number | string>({
            query: (id: number | string) => ({
                url: `users/data/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: IServerResponse<IUserAllInfo>) => {
                response.data = usersService.prepareUserData(response.data, response.dictionary);

                return response;
            },
        }),
        getAuthUser: build.query<IServerResponse<IUserAllInfo>, number | string>({
            query: (id: number | string) => ({
                url: `users/data/${id}`,
                method: 'GET',
            }),
        }),
        editUser: build.mutation<IServerResponse<any>, any>({
            query: (body) => ({
                url: 'users/edit/',
                method: 'POST',
                body: JSON.stringify(body),
            }),
            invalidatesTags: [{ type: 'Users', id: 'LIST' }],
        }),
        deleteUser: build.mutation<IServerResponse<any>, any>({
            query: (id) => ({
                url: `users/delete/${id}`,
                method: 'GET',
            }),
            invalidatesTags: [{ type: 'Users', id: 'LIST' }],
        }),
        setUserAvatar: build.query<IServerResponse<IUserAllInfo>, FormData>({
            query: (formData) => ({
                url: `users/avatar_add/`,
                method: 'POST',
                body: formData,
                formData: true,
            }),
        }),
        deleteUserAvatar: build.query<IServerResponse<IUserAllInfo>, number | string>({
            query: (id: number | string) => ({
                url: `users/avatar_delete/${id}`,
                method: 'GET',
            }),
        }),
        setUserStatus: build.query<IServerResponse<[]>, IChangeUserStatus>({
            query: (body) => ({
                url: `users/status/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        changeUserPassword: build.query<IServerResponse<[]>, IChangeUserPassword>({
            query: (body) => ({
                url: `users/password_change/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        sendNewUserPassword: build.query<IServerResponse<[]>, number>({
            query: (id) => ({
                url: `users/send_requisites/${id}`,
                method: 'POST',
            }),
        }),
        addHerriotData: build.query<IServerResponse<IUserAllInfo>, IHerriotType>({
            query: (body) => ({
                url: `users/herriot_req_add/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        addUserRole: build.query<IServerResponse<IUserAllInfo>, IRolesChangeParam>({
            query: (body) => ({
                url: `users/role_add/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        deleteUserRole: build.query<IServerResponse<IUserAllInfo>, IRolesChangeParam>({
            query: (body) => ({
                url: `users/role_delete/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        addUserLocation: build.query<IServerResponse<IUserAllInfo>, ILocationChangeParam>({
            query: (body) => ({
                url: `users/location_add/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        deleteUserLocation: build.query<IServerResponse<IUserAllInfo>, ILocationChangeParam>({
            query: (body) => ({
                url: `users/location_delete/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        addUserCompany: build.query<IServerResponse<IUserAllInfo>, ILocationChangeParam>({
            query: (body) => ({
                url: `users/company_add/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        deleteUserCompany: build.query<IServerResponse<IUserAllInfo>, ILocationChangeParam>({
            query: (body) => ({
                url: `users/company_delete/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
        registrationUser: build.query<IServerResponse<IUserAllInfo>, IRegistration>({
            query: (body) => ({
                url: `users/create/`,
                method: 'POST',
                body: JSON.stringify(body),
            }),
        }),
    }),
});

export const {
    useLazyGetUsersQuery,
    useGetUsersQuery,
    useGetUserQuery,
    useLazyGetUserQuery,
    useDeleteUserMutation,
    useEditUserMutation,
    useLazySetUserAvatarQuery,
    useLazyDeleteUserAvatarQuery,
    useLazySetUserStatusQuery,
    useLazyChangeUserPasswordQuery,
    useLazySendNewUserPasswordQuery,
    useLazyAddHerriotDataQuery,
    useLazyAddUserRoleQuery,
    useLazyDeleteUserRoleQuery,
    useLazyAddUserLocationQuery,
    useLazyDeleteUserLocationQuery,
    useLazyGetAuthUserQuery,
    useLazyAddUserCompanyQuery,
    useLazyDeleteUserCompanyQuery,
    useLazyRegistrationUserQuery,
} = usersApi;
